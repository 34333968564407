document.addEventListener("DOMContentLoaded", () => {
    showCredits()
    toggleMobile()
    scrollTop()
});

window.addEventListener( 'scroll', () => {
    toggleScroller( 'scrollTop' )
})

const toggleMobile = () => {
    const hamburguer = document.getElementById('hamburguer-icon')
    const mobileNav = document.getElementById('mobile-nav')
    const body = document.getElementsByTagName('body')[0];

    hamburguer.addEventListener('click', () => {
        hamburguer.classList.toggle('open')
        mobileNav.classList.toggle('active')
        body.classList.toggle('blocked');
    })
}

const showCredits = () => {
    console.log("%c>XYZZY estudio web_", "font-weight: 200; font-size: 30px; color:white; background-color:#ff1a51; padding:40px;");
    console.log("Checkout our work on: https://www.xyzzyestudioweb.com")
}

const toggleScroller = ( el ) => {

    let scrollTop = document.getElementById( el )

    if (window.scrollY >= 300) {
        scrollTop.style.opacity = 1
    } else {
        scrollTop.style.opacity = 0
    }
}

const scrollTop = () => {

    let scrollTop = document.getElementById( 'scrollTop' )

    scrollTop.addEventListener( 'click', () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' 
        });
    })
}